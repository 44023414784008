import "../scss/main.scss";

import { showModalWithData, closeModal } from "./components/modal";
import { isIOS } from "./components/deviceCheck";
import { sidebarChannelsHandler } from "./components/sidebarChannel";
import adjustPlayerWrapperDimensions from "./player/modules/adjustPlayerWrapperDimensions";

function handleContentIntersection(entries) {
    entries.map((entry) => {
        if (entry.isIntersecting) {
            if (entry.target.classList.contains('lazyload-content')) {
                if (entry.isIntersecting) {
                    entry.target.classList.remove('lazyload-content');
                    let contentUrl = entry.target.getAttribute('data-url');
                    loadContent(contentUrl).then((data) => {
                        entry.target.innerHTML = data.contentHtml;
                        if (entry.target.querySelector('.swiper-container')) {
                            setUpSliders('#' + entry.target.getAttribute('id') + ' .swiper-container');
                        }
                    })
                        .catch((err) => {
                            console.log("error", err.message);
                        });
                }
            }
        }
    });
}

async function loadContent(contentUrl) {

    let headers = {
        'Accept': 'application/json',
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
    };
    const token = localStorage.getItem('token') ?? '';
    if (token) {
        headers = {
            'Accept': 'application/json',
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "Authorization": `Bearer ${token}`
        };
    }

    const response = await fetch(contentUrl, {
        method: "GET",
        headers: headers,
    });

    if (response.status !== 200) {
        throw new Error("cannot fetch data");
    }

    let responseData = await response.json();

    return responseData;
}

const observer = new IntersectionObserver(handleContentIntersection);
window.addEventListener("load", (event) => {
    document.querySelectorAll('.lazyload-content').forEach((i) => {
        if (i) {
            observer.observe(i);
        }
    });

    /*setTimeout(function () {
        let desktopSticky = document.getElementById('desktop-sticky-menu');
        desktopSticky.classList.add('d-lg-block');
        desktopSticky.classList.remove('euro-on');
        desktopSticky.classList.remove('d-lg-flex');
        desktopSticky.classList.remove('flex-column');
        desktopSticky.classList.remove('align-items-end');
    }, 7000);*/
});

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', function(event) {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    if (document.body.clientWidth > 991) {
        //reset desktop sticky
        let desktopSticky = document.getElementById('desktop-sticky-menu');
        let mainSlider = document.querySelector('.main-slider-swiper');
        if(desktopSticky && mainSlider){
            let sliderRect = mainSlider.getBoundingClientRect();
            let desktopTop = sliderRect.bottom  + 60;
            desktopSticky.style.top = desktopTop + 'px';
        }
    }
});


let changeStickyPositionOnScroll = function ()
{
    if (document.body.clientWidth > 991) {
        let mainSlider = document.querySelector('.main-slider-container');
        let desktopSticky = document.getElementById('desktop-sticky-menu');
        let headerOffset = (document.getElementById('site-header').offsetHeight + 100);
        let lastKnownScrollPosition = window.scrollY;
        if (desktopSticky && mainSlider) {
            let sliderOffsetFromTop = mainSlider.getBoundingClientRect().top + 70;
            let sliderHeight = mainSlider.offsetHeight;
            if (lastKnownScrollPosition > sliderHeight) {
                desktopSticky.classList.add("scrolled");
                desktopSticky.style.top = headerOffset + 'px';
            } else {
                desktopSticky.classList.remove("scrolled");
                desktopSticky.style.top = sliderOffsetFromTop + sliderHeight + 'px';
            }
        }
        if (desktopSticky && !mainSlider) {
            if (lastKnownScrollPosition > headerOffset) {
                desktopSticky.classList.add("scrolled");
                desktopSticky.style.top = headerOffset + 'px';
            } else {
                desktopSticky.classList.remove("scrolled");
                desktopSticky.style.top = 60 +'%';
            }
        }
    }
}
window.addEventListener("scroll", changeStickyPositionOnScroll);

// document.body.addEventListener('click', (event) => {
//     const parentLink = event.target.closest('.save-go-back-url');
//     if (!parentLink) {
//         return true;
//     }
//     //save current url for after sign up process
//     const currentUrl = window.location.href;
//     localStorage.setItem('signUpGoBackUrl', currentUrl);
//     //set 30 minutes expiration
//     localStorage.setItem('signUpGoBackExpiration', Date.now() + 30 * 60 * 1000);
//     return true;
// });

document.addEventListener("DOMContentLoaded", function(event) {
    const currentUrl = window.location.href;
    localStorage.setItem('previousUrl', currentUrl);

    if (document.body.clientWidth > 1299) {
        document.body.classList.add('show-sidebar');
    }
    document.body.classList.remove('d-1300-show-sidebar');
    const scrollElement = document.getElementById('scroll-to-top');
    if(scrollElement){
        document.addEventListener("scroll", (event) => {
            if (document.documentElement.scrollTop > 500) {
                scrollElement.classList.add('show');
            } else {
                scrollElement.classList.remove('show');
            }
        });
        scrollElement.addEventListener('click', (event) => {
            window.scrollTo(0,0);
            return false;
        });
    }

    let modals = document.querySelectorAll(".modal-popup");
    modals.forEach((modal) => {
        modal.addEventListener("click", (event) => {
            if (event.target.matches(".close-modal") || !event.target.closest(".modal-container") && event.target.tagName !== 'BUTTON') {
                closeModal();
            }
        });
    });

    let modalButton = document.querySelector(".loadAjaxModal");
    if (modalButton) {
        modalButton.onclick = function(event) {
            event.preventDefault();
            let ajaxUrl = this.dataset.ajaxurl;
            fetch(ajaxUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "text/html",
                    "X-Requested-With": "XMLHttpRequest"
                }
            })
                .then(response => response.text())
                .then(data => {
                    showModalWithData(data);
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
    }

    /* cons showNavbar = (toggleId, navId) => {
         const toggle = document.getElementById(toggleId),
             nav = document.getElementById(navId)

         // Validate that all variables exist
             if (toggle && nav) {
                 toggle.addEventListener('click', () => {
         // show navbar
                     nav.classList.toggle('show');
                     nav.querySelector('.nav-title').classList.toggle('d-none');

                     let streamers = nav.querySelectorAll('.streamer-name');
                     streamers.forEach(function (element) {
                         element.classList.toggle('d-none');
                     });
                     let menuNames = nav.querySelectorAll('.nav_name');
                     menuNames.forEach(function (element) {
                         element.classList.toggle('d-none');
                     });

                     let streamersState = nav.querySelectorAll('.streamer-state');
                     streamersState.forEach(function (element) {
                         element.classList.toggle('d-none');
                     });
             // change icon
                     if (toggle.classList.contains('bi-arrow-bar-left')) {
                         toggle.classList.remove('bi-arrow-bar-left');
                         toggle.classList.add('bi-arrow-bar-right');
                     } else {
                         toggle.classList.add('bi-arrow-bar-left');
                         toggle.classList.remove('bi-arrow-bar-right');
                     }

         // add padding to header
                 })
             }
     }*/

    document.querySelectorAll('.toggle-menu').forEach(el => el.addEventListener('click', event => {
        document.body.classList.toggle('show-sidebar');
        if (document.body.classList.contains('show-sidebar')) {
            document.body.setAttribute('scroll', 'no');
        } else {
            document.body.removeAttribute('scroll');
        }
        // if not mobile
        [].forEach.call(document.querySelectorAll(".hide-on-toggle-js.show"), function(el) {
            el.classList.remove("show");
        });
        [].forEach.call(document.querySelectorAll(".has-submenu.submenu-open"), function(el) {
            el.classList.remove("submenu-open");
        });
        if (document.getElementById('video-player')) {
            adjustPlayerWrapperDimensions();
        }
    }));

    document.querySelectorAll('.backdrop-toggle-menu').forEach(el => el.addEventListener('click', event => {
        document.body.classList.toggle('show-sidebar');
        if (document.body.classList.contains('show-sidebar')) {
            document.body.setAttribute('scroll', 'no');
        } else {
            document.body.removeAttribute('scroll');
        }
        if (document.getElementById('video-player')) {
            adjustPlayerWrapperDimensions();
        }
    }));

    const submenuItems = document.querySelectorAll('.has-submenu');
    submenuItems.forEach(el => el.addEventListener('click', event => {
        let submenuId;
        if (event.target.classList.contains('has-submenu')) {
            submenuId = event.target.getAttribute("data-submenu-container");
            event.target.classList.toggle('submenu-open');
        } else {
            submenuId = event.target.closest('.has-submenu').getAttribute("data-submenu-container");
            event.target.closest('.has-submenu').classList.toggle('submenu-open');
        }
        const submenuContainer = document.getElementById(submenuId);
        submenuContainer.classList.toggle('show');
    }));

    document.querySelectorAll('.toggle-tab').forEach(el => el.addEventListener('click', event => {
        const parentContainer = el.closest('.tab-container');
        const parentContainerId = el.closest('.tab-container').id;
        const parentTabsContainer = el.closest('.toggle-tabs');
        if (el.classList.contains('channel-toggle')) {
            //channel schedule
            let outerParent = parentContainer.parentElement.parentElement;
            // make all tabs and toggles inactive
            [].forEach.call(outerParent.querySelectorAll(".channel-toggle"), function(el) {
                el.classList.remove("active-tab");
            });
            [].forEach.call(outerParent.querySelectorAll(".channel-tab-content"), function(el) {
                el.classList.add("d-none");
            });
            //activate only the ones based on channel key
            let activeChannel = el.dataset.channel;
            let activeChannelTabs = document.querySelectorAll(".channel-toggle[data-channel=" + activeChannel + "]");
            activeChannelTabs.forEach((activeChannelTab) => {
                activeChannelTab.classList.add("active-tab");
                document.querySelector('#' + activeChannelTab.dataset.target + '').classList.remove('d-none');
            });
        } else {
            [].forEach.call(parentTabsContainer.querySelectorAll(".toggle-tab.active-tab"), function(el) {
                el.classList.remove("active-tab");
            });
            //activate current tab
            el.classList.add("active-tab");

            [].forEach.call(parentContainer.querySelectorAll('#' + parentContainerId + " > .tab-content"), function(el) {
                el.classList.add("d-none");
            });
            document.getElementById(el.getAttribute('data-target')).classList.remove("d-none");
        }
        return false;

    }));

    document.querySelectorAll('.toggle-filter').forEach(el => el.addEventListener('click', event => {
        const parentContainer = el.closest('.filter-container');
        const parentContainerId = el.closest('.filter-container').id;
        const parentTabsContainer = el.closest('.toggle-filters');
        [].forEach.call(parentTabsContainer.querySelectorAll(".toggle-filter.active-filter"), function(el) {
            el.classList.remove("active-filter");
        });
        el.classList.add("active-filter");
        [].forEach.call(parentContainer.querySelectorAll('#' + parentContainerId + " .filter-content"), function(el) {
            el.classList.add("d-none");
        });
        [].forEach.call(parentContainer.querySelectorAll('#' + parentContainerId + " ." + el.getAttribute('data-target')), function(el) {
            el.classList.remove("d-none");
        });
        return false;
    }));

    document.querySelectorAll('.toggle-content').forEach(el => el.addEventListener('click', event => {
        const parentContainer = el.closest('.toggle-content');
        parentContainer.classList.toggle('active');
        return false;
    }));

    /* sticky menu */
    let desktopSticky = document.getElementById('desktop-sticky-menu');
    let mainSlider = document.querySelector('.main-slider-swiper');
    if(desktopSticky){
        if(mainSlider){
            let sliderRect = mainSlider.getBoundingClientRect();
            let desktopTop = sliderRect.bottom  + 60;
            desktopSticky.style.top = desktopTop + 'px';
        }
        desktopSticky.classList.add('open');
    }

    document.addEventListener('click', async (event) => {
        const element = event.target.closest('.sticky-menu-item-button-content');
        const closeElement = event.target.closest('.close-sticky-content');
        if(!element && !closeElement) return;
        [].forEach.call(document.querySelectorAll('.sticky-content.open'), function(el) {
            el.classList.remove('open');
        });
        if(element && element.classList.contains('open')){
            [].forEach.call(document.querySelectorAll('.sticky-menu-item-button-content.open'), function(el) {
                el.classList.remove('open');
            });
            document.getElementById('sticky-backdrop').classList.remove('open');
            document.body.removeAttribute('scroll');
            document.body.classList.remove('show-sticky');
        }else if(element){
            [].forEach.call(document.querySelectorAll('.sticky-menu-item-button-content.open'), function(el) {
                el.classList.remove('open');
            });
            element.classList.add('open');
            let activeContent = element.dataset.target;
            document.getElementById(activeContent).classList.add('open');
            document.getElementById('sticky-backdrop').classList.add('open');
            document.body.setAttribute('scroll', 'no');
            document.body.classList.add('show-sticky');
        }
        if(closeElement){
            [].forEach.call(document.querySelectorAll('.sticky-menu-item-button-content.open'), function(el) {
                el.classList.remove('open');
            });
            document.getElementById('sticky-backdrop').classList.remove('open');
            document.body.removeAttribute('scroll');
            document.body.classList.remove('show-sticky');
        }
        return false;
    });

    document.querySelectorAll('.backdrop-toggle-sticky').forEach(el => el.addEventListener('click', event => {
        if (el.classList.contains('open')) {
            [].forEach.call(document.querySelectorAll('.sticky-content.open'), function(el) {
                el.classList.remove('open');
            });
            [].forEach.call(document.querySelectorAll('.sticky-menu-item-button-content.open'), function(el) {
                el.classList.remove('open');
            });
            document.body.removeAttribute('scroll');
            document.body.classList.remove('show-sticky');
            el.classList.remove('open');
        }
        return false;
    }));

    document.addEventListener("click", function(e){
        const el = e.target.closest('.sticky-bonus-code-button');
        if(!el) return true;
        el.classList.add("revealed");
        let bonusCodeField = el.querySelector('.bonus-code-text');
        let bonusCodeWrap = el.querySelector('.bonus-code-wrap');
        bonusCodeWrap.classList.remove('ps-3','pe-2','pe-lg-1');
        bonusCodeWrap.classList.add('w-100');
        bonusCodeField.textContent = bonusCodeField.dataset.code;
        return false;
    });

    /*===== LINK ACTIVE =====*/
    const linkColor = document.querySelectorAll('.nav_link')
    function colorLink() {
        if (linkColor) {
            linkColor.forEach(l => l.classList.remove('active'))
            this.classList.add('active')
        }
    }

    if (window.location.hash){
        let hashId = window.location.hash.substring(1);
        let hashContainer = document.getElementById('' + hashId + 'Container');
        if(hashContainer){
            window.scrollTo(0, hashContainer.offsetTop - document.getElementById('site-header').offsetHeight - 30);
        }
    }

});

sidebarChannelsHandler();

