async function fetchChannelsForSidebar() {
    try {
        const response = await fetch("/fetchChannels/sidebar", {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            }
        });

        try {
            return await response.json();
        } catch (error) {
            console.log(`cant parse response to json: ${error}`);
        }

    } catch (error) {
        console.log(`fetch channels for sidebar error: ${error}`);
    }
}

function getChannelStatus(channel) {
    let result = false;
    if(channel && channel.is_live) {
        result = channel.is_live == "0" ? false : true;
    }
    return result;
}

function updateDOMChannelDesktop(id, channel) {
    const channelElement = document.getElementById(id);
    const currentUrl = window.location.href;
    const liveClassName = 'live-menu-item';
    const channelStateClassName = 'live';
    const channelStateOfflineText = 'Offline';
    const channelStateLiveText = 'Live';
    const status = getChannelStatus(channel);
    const updateStateElement = (status) => {
        const channelStateElement = channelElement.querySelector('.channel-state');
        if (!channelStateElement) {
            return;
        }
        if (!status) {
            channelStateElement.classList.remove(channelStateClassName);
            channelStateElement.innerText = channelStateOfflineText;
            return;
        }
        channelStateElement.classList.add(channelStateClassName);
        channelStateElement.innerText = channelStateLiveText;
    }
    let href;
    if (!channelElement) {
        //console.log(`channel element not found: ${id}`);
        return;
    }
    updateStateElement(status);
    if (!status) {
        href = `/${channel.permalink}`;
        channelElement.href = href;
        channelElement.classList.remove(liveClassName);
        return;
    }
    href = `/stream/${channel.permalink}`;
    channelElement.href = href;
    channelElement.classList.add(liveClassName);
}

function updateDOMChannelMobile(id, channel) {
    const channelElement = document.getElementById(id);
    const linkElement = channelElement?.querySelector('[data-channel_link]');
    const status = getChannelStatus(channel);
    const liveClassName = 'live';
    const createLiveSpanElement = () => {
        const span = document.createElement('span');
        span.classList.add(...[
            "channel-state-box",
            "position-absolute",
            "text-center",
            "font-fff",
            "font-weight-bold"
        ]);
        span.innerText = "LIVE";
        return span;
    }
    if (!channelElement) {
        //console.log(`channel element not found: ${id}`);
        return;
    }
    if(!status) {
        linkElement.href = `/${channel.permalink}`;
        channelElement.classList.remove(liveClassName);
        return;
    }

    linkElement.href = `/stream/${channel.permalink}`;
    channelElement.classList.add(liveClassName);
    channelElement.appendChild(createLiveSpanElement());
}

export function sidebarChannelsHandler() {
    window.addEventListener("load", (event) => {
        fetchChannelsForSidebar()
            .then((channels) => {
                channels.forEach((channel) => {
                    updateDOMChannelDesktop(`channel-${channel.id}`, channel);
                    updateDOMChannelMobile(`channel-mobile-${channel.id}`, channel);
                });
            })
            .catch((err) => {
                console.log("rejected", err.message);
            })
    });
}


