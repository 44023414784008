"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function addFixedClassToAspectRatioDiv() {
    if (document.body.clientWidth > 1299) {
        const videoAspectDiv = document.querySelector('.video-aspect');
        if (!videoAspectDiv) {
            return;
        }
        const videoWidth = videoAspectDiv.offsetWidth;
        const videoHeight = document.body.clientHeight - 196;
        const videoRatio = Math.round(100 * videoWidth / videoHeight) / 100;
        if (videoRatio >= 1.77) {
            videoAspectDiv.classList.add('fixed-height');
        }
        else {
            videoAspectDiv.classList.remove('fixed-height');
        }
    }
}
function adjustPlayerWrapperDimensions() {
    addFixedClassToAspectRatioDiv();
    let height = undefined;
    const videoWrapper = document.querySelector('.video-wrapper');
    const chatWrapper = document.querySelector('.video-chat-container');
    const videoPlayerWrapper = document.getElementById('video-player');
    if (!videoWrapper || !chatWrapper) {
        return;
    }
    videoWrapper.scrollTop = 0;
    const archivedDescriptionWrapper = document.querySelector('.archived-video__description-container');
    const liveDescriptionWrapper = document.getElementById('channel_image_title_container');
    if (archivedDescriptionWrapper) {
        height = archivedDescriptionWrapper.getBoundingClientRect().height;
    }
    if (liveDescriptionWrapper) {
        height = liveDescriptionWrapper.getBoundingClientRect().height;
    }
    const root = document.documentElement;
    root.style.setProperty("--description-height", `${height}px`);
    if (videoPlayerWrapper) {
        const videoPlayerHeight = videoPlayerWrapper.getBoundingClientRect().height;
        root.style.setProperty("--video-player-height", `${videoPlayerHeight}px`);
    }
}
exports.default = adjustPlayerWrapperDimensions;
